//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    selDateStr: function selDateStr() {
      var now = new Date();

      if (this.resol === 'daily') {
        if (now.getDate() === this.selected_date.getDate() && now.getMonth() === this.selected_date.getMonth()) {
          if (this.resol === 'daily') return this.$t('cloud.title.now_date');else return this.$t('cloud.title.now_month');
        }

        return this.$utility.GetDateTimeStr('$mm.$dd', this.selected_date);
      } else {
        if (now.getFullYear() === this.selected_date.getFullYear() && now.getMonth() === this.selected_date.getMonth()) {
          if (this.resol === 'daily') return this.$t('cloud.title.now_date');else return this.$t('cloud.title.now_month');
        }

        return this.$utility.GetDateTimeStr('$yyyy.$mm', this.selected_date);
      }
    }
  },
  data: function data() {
    return {
      resol: 'daily',
      selected_date: new Date()
    };
  },
  methods: {
    onPrev: function onPrev() {
      var new_date = this.selected_date;
      if (this.resol === 'daily') new_date.setDate(new_date.getDate() - 1);else new_date.setMonth(new_date.getMonth() - 1);
      this.selected_date = new Date(new_date);
      this.onChange();
    },
    onNext: function onNext() {
      var new_date = this.selected_date;
      if (this.resol === 'daily') new_date.setDate(new_date.getDate() + 1);else new_date.setMonth(new_date.getMonth() + 1);
      this.selected_date = new Date(new_date);
      this.onChange();
    },
    onSelectDate: function onSelectDate() {
      if (this.resol === 'daily') {
        this.$refs.datepicker.showPicker();
      } else {
        this.$refs.monthpicker.showPicker();
      }
    },
    onChange: function onChange() {
      this.$emit('change', this.resol, this.selected_date);
    }
  }
};