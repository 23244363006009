<template>
  <div>
    <CRow v-if="device_info" class="justify-content-center">
      <CCol xl="12" xxl="10">
        <CCard class="mt-4">
          <CCardBody>
            <CRow class="justify-content-between">
              <div>
                <el-button class="text-dark" type="text" @click="onBack">
                  <i class="el-icon-arrow-left"></i>
                  {{ $t('cloud.title.go_back') }}
                </el-button>
              </div>
              <div>
                <h4 class="mt-2">{{ $t('cloud.title.device_detail_view') }}</h4>
              </div>
              <div></div>
            </CRow>
          </CCardBody>
        </CCard>

        <CRow>
          <CCol xl="12" xxl="6">
            <CCard>
              <CCardHeader>
                <CRow class="justify-content-between px-3">
                  <h6 class="mt-2">{{ $t('cloud.title.device_info') }}</h6>
                  <CButtonGroup size="sm">
                    <CButton :disabled="device_info.status!=='online'" :color="device_info.disarm_status==='arm'?'info':'secondary'" @click="onArm()">{{$t('cloud.title.disarm_arming')}}</CButton>
                    <CButton :disabled="device_info.status!=='online'" :color="device_info.disarm_status==='disarm'?'danger':'secondary'" @click="onDisarm()">{{$t('cloud.title.disarm_disarming')}}</CButton>
                  </CButtonGroup>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <small>{{ $t('cloud.title.device_name') }} / {{ $t('cloud.title.note') }}</small>
                <h6 class="text-dark mt-1 mb-3">
                  {{device_info.name}}
                  <small v-if="device_info.memo"> - {{device_info.memo}}</small>
                  <CBadge class="ml-2" :color="$utility.getBadgeColor(device_info.status)">{{$t(`cloud.title.status_${device_info.status}`)}}</CBadge>
                </h6>
                <small>{{ $t('cloud.title.branch') }}</small>
                <h6 class="text-dark mt-1 mb-3">{{device_info.branch_name}}</h6>
                <small>{{ $t('cloud.title.model') }}</small>
                <h6 class="text-dark mt-1 mb-3">{{device_info.model}}</h6>
                <small>{{ $t('cloud.title.license_num_ch') }}</small>
                <h6 class="text-dark mt-1 mb-3">{{device_info.num_ch}}</h6>
                <small>MAC</small>
                <h6 class="text-dark mt-1 mb-3">{{device_info.mac_addr}}</h6>
                <small>{{ $t('cloud.title.swver') }}</small>
                <h6 class="text-dark mt-1">{{device_info.swver}}</h6>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xl="12" xxl="6">
            <CCard>
              <CCardHeader>
                <h6 class="mt-2">{{ $t('cloud.title.device_event') }}</h6>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  :items="list"
                  :fields="FIELDS"
                  :items-per-page="10"
                  size="sm"
                >
                  <td slot="report_at" slot-scope="{item}">
                    {{ $utility.zonedDateTimeStr(new Date(item.report_at), capability.user_profile.timezone, 'yyyy-MM-dd HH:mm:ss') }}
                  </td>
                </CDataTable>
                <CRow class="justify-content-end mt-3" v-if="pageTotal > 1">
                  <CPagination
                    :activePage.sync="pageCurrent"
                    :pages="pageTotal"
                    @update:activePage="onUpdatePage"
                  >
                  </CPagination>
                  <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <CRow>
          <CCol xl="12" xxl="6">
            <ChartAlarms
              :device_info="device_info"
            />
          </CCol>
          <CCol xl="12" xxl="6">
            <ChartUtilization
              :device_info="device_info"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol xl="12" xxl="6">
            <ChartUsageCpu
              :device_info="device_info"
            />
          </CCol>
          <CCol xl="12" xxl="6">
            <ChartUsageMemory
              :device_info="device_info"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <DeviceArmModal ref="DeviceArmModal" @ok="initData" />
    <DeviceDisarmModal ref="DeviceDisarmModal" @ok="initData" />
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import {mapState} from 'vuex';
import ChartUtilization from './ChartUtilization.vue';
import ChartUsageCpu from './ChartUsageCpu.vue';
import ChartUsageMemory from './ChartUsageMemory.vue';
import ChartAlarms from './ChartAlarms.vue'
import DeviceArmModal from '@/components/modals/DeviceArmModal.vue'
import DeviceDisarmModal from '@/components/modals/DeviceDisarmModal.vue'

export default {
  name: 'DeviceView',

  components: {
    ChartUtilization,
    ChartUsageCpu,
    ChartUsageMemory,
    ChartAlarms,
    DeviceArmModal,
    DeviceDisarmModal,
  },

  computed: {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      device_id: null,
      device_info: null,
      list: [],
      FIELDS: [
        {key: 'report_at', label: this.$t('cloud.title.time')},
        {key: 'reason', label: this.$t('cloud.title.event')}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
    }
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    onBack() {
      this.$router.push('/setup/devices');
    },
    async initData() {
      await this.getDeviceInfo();
      await this.getDeviceEvent();
    },
    async getDeviceInfo() {
      this.device_id = this.$route.query.device_id;
      if (!this.device_id) {
        return;
      }
      const response = await axios.get(`/api/bridge/${this.device_id}/`)
      this.device_info = this.$utility.copy(response.data);
    },
    async getDeviceEvent() {
      let query_string = '';
      const params = {
        page: this.pageCurrent,
        ipp: 7
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      const url = `/api/devicelog/${this.device_info.device_id}/event/${query_string}`;
      await axios.get(url)
        .then(response => {
          this.list = this.$utility.copy(response.data.list);
          this.pageTotal = response.data.page.total;
          this.pageCurrent = response.data.page.current;
          this.count = response.data.page.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      await this.getDeviceEvent();
    },
    async onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      await this.getDeviceEvent();
    },
    onArm() {
      if (this.device_info.disarm_status === 'arm') return;
      this.$refs.DeviceArmModal.open(this.device_info);
    },
    onDisarm() {
      if (this.device_info.disarm_status === 'disarm') return;
      this.$refs.DeviceDisarmModal.open(this.device_info);
    },
  }
}
</script>
