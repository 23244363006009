<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="true"
    :centered="true"
    color="secondary"
  >
    <el-form label-width="30%">
      <el-form-item :label="$t('cloud.title.device')" class="mt-4 mb-0">
        <el-input :value="param.name" readonly></el-input>
        <small>{{param.device_id}}</small>
      </el-form-item>
    </el-form>
    <template #header>
      <h6 class="modal-title text-dark font-weight-bold">{{title}}</h6>
      <CButtonClose @click="show = false" class="text-dark" />
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onOk" color="primary">{{$t('button.apply')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ArmConfirmModal',

  data() {
    return {
      show: false,
      title: this.$t('cloud.title.disarm_arming'),
      param: {
        name: null,
        device_id: null
      }
    }
  },
  methods: {
    open(param) {
      this.param = this.$utility.copy(param);
      this.show = true;
    },
    onCancel() {
      this.$emit('cancel');
      this.param = {
        name: null,
        device_id: null
      };
      this.show = false;
    },
    onOk() {
      let payload = {
        disarm_status: 'arm',
        duration_secs: 0
      }
      axios.put(`/api/bridge/${this.param.device_id}/disarm/`, payload)
        .then(() => {
          this.$notify.success({
            title: this.$t('cloud.title.success'),
            message: this.$t('cloud.title.request_done'),
            offset: 30
          });
          this.$emit('ok');
          this.show = false;
          this.param = {
            name: null,
            device_id: null
          };
        }).catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: error.response.data.error_detail,
            offset: 30
          });
        })
    },
  }
}
</script>
