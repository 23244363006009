<template>
  <div
    :loading="loading"
  >
    <ChartContainer
      :title="$t('cloud.title.device_alarm')"
      @change="onChange"
    >
      <template slot="chart">
        <CChartBar
          :datasets="chartData"
          :labels="chartLabel"
          :options="chartOption"
        />
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import qs from 'qs';
import ChartContainer from './ChartContainer.vue';
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'UsageMemoryChartView',

  props: {
    device_info: { type: Object, required: true }
  },

  components: {
    ChartContainer,
    CChartBar
  },

  computed: {
    ...mapState([
      'capability'
    ]),
    chartData() {
      const rtn = [];
      for (const i in this.stats) {
        const record = {
          label: this.labels[i],
          data: this.stats[i].map(el => el.value),
          backgroundColor: this.colors[i],
          stack: this.stack?this.stackNames[i]:undefined
        };
        rtn.push(record);
      }
      return rtn;
    },
    chartLabel() {
      let rtn = [];
      let stat = this.stats[0];
      if (stat.length === 24) {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          // const hour = d.getHours();
          // // const ampm = this.$t('cloud.title.ampm_am');
          // const ampm = '';
          // // if (hour >= 12) ampm = this.$t('cloud.title.ampm_pm');
          // if (i % 4 === 0) rtn.push(ampm+' '+hour);
          // else rtn.push(hour);
          rtn.push(this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'H'));
        }
      } else {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          const date = d.getDate();
          rtn.push(`${date}`);
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            stacked: this.stack,
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.y_unit
            },
            stacked: this.y_stack,
            ticks: {
              beginAtZero: true,
              // stepSize: 1
              // maxTicksLimit: 5,
              // stepSize: Math.ceil(250 / 5),
              // max: 250
              suggestedMax: 10
            },
            gridLines: {
              display: true
            }
          }]
        },
        aspectRatio: this.aspect_ratio
      };
    }
  },
  data() {
    return {
      loading: true,
      stats: [[]], // [{value:숫자, x:시간}, ...] 
      labels: [this.$t('cloud.title.device_alarm')],
      colors: ['#2982cc'],
      y_unit: '',
      aspect_ratio: null,
      stack: false,
      stackNames: [],
      y_stack: false
    }
  },
  mounted() {
    this.getData('daily', new Date());
  },
  methods: {
    onChange(resol, date) {
      this.getData(resol, date);
    },
    getData(resol, date) {
      this.loading = true;
      let query_string = '';
      const params = {
        resol: resol,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate()
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      const url = `/api/alarm/device/${this.device_info.device_id}/count/${query_string}`;
      axios.get(url)
        .then(response => {
          this.stats = [response.data.map(el => ({value: el.value, x: el.time}))];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
