<template>
  <div
    :loading="loading"
  >
    <ChartContainer
      :title="$t('cloud.title.device_usage_cpu')"
      @change="onChange"
    >
      <template slot="chart">
        <CChartLine
          :datasets="chartData"
          :labels="chartLabel"
          :options="chartOption"
        />
      </template>
    </ChartContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios';
import qs from 'qs';
import ChartContainer from './ChartContainer.vue';
import { CChartLine } from '@coreui/vue-chartjs'
import { hexToRgba } from '@coreui/utils/src'

export default {
  name: 'UsageCpuChartView',

  props: {
    device_info: { type: Object, required: true }
  },

  components: {
    ChartContainer,
    CChartLine
  },

  computed: {
    ...mapState([
      'capability'
    ]),
    chartData() {
      // let delta = {
      //   label: 'Count',
      //   data: this.stats.map(el => el.delta),
      //   borderColor: '#f0c33c',
      //   fill: false,
      //   tension: 0.4
      // };
      // let inc = {
      //   label: 'Increase',
      //   data: this.stats.map(el => el.inc),
      //   borderColor: '#358ec4',
      //   fill: false,
      //   tension: 0.4
      // };
      // let dec = {
      //   label: 'Decrease',
      //   data: this.stats.map(el => el.dec),
      //   borderColor: '#e65054',
      //   fill: false,
      //   tension: 0.4
      // };
      // return [delta, inc, dec];
      const rtn = [];
      for (const i in this.stats) {
        let bgColor = null;
        if (this.bgColors && this.bgColors.length > i && this.bgColors[i] !== 'none') {
          bgColor = hexToRgba(this.bgColors[i], 20);
        }
        let lineStyle = null;
        if (this.lineStyle && this.lineStyle.length > i && this.lineStyle[i] !== 'line') {
          lineStyle = [8,5];
        }
        const record = {
          label: this.labels[i],
          data: this.stats[i].map(el => el.value),
          borderColor: this.colors[i],
          pointHoverBackgroundColor: this.colors[i],
          borderWidth: 1.8,
          tension: 0,
          fill: false
        };
        if (bgColor) {
          record.fill = true;
          record.backgroundColor = bgColor;
        }
        if (lineStyle) {
          record.borderDash = [4, 2];
        }
        rtn.push(record);
      }
      return rtn;
    },
    chartLabel() {
      let rtn = [];
      let stat = this.stats[0];
      if (stat.length === 24) {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          // const hour = d.getHours();
          // // const ampm = this.$t('cloud.title.ampm_am');
          // const ampm = '';
          // // if (hour >= 12) ampm = this.$t('cloud.title.ampm_pm');
          // if (i % 4 === 0) rtn.push(ampm+' '+hour);
          // else rtn.push(hour);
          rtn.push(this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'H'));
        }
      } else {
        for (let i in stat) {
          const d = new Date(stat[i].x);
          const date = d.getDate();
          rtn.push(`${date}`);
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          display: this.legend,
          position: 'top', // top, right, bottom, left
          align: 'center', // 'start', 'center', 'end'
          labels: {
            fontSize: 12
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.x_unit
            },
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.y_unit
            },
            ticks: {
              beginAtZero: true,
              max: 100
            },
            gridLines: {
              display: true
            }
          }]
        },
        aspectRatio: this.aspect_ratio,
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      };
    }
  },
  data() {
    return {
      loading: true,
      stats: [[]], // [{value:숫자, x:시간}, ...] 
      labels: [this.$t('cloud.title.device_usage_cpu')],
      colors: ['#2982cc'],
      bgColors: ['#2982cc'],
      lineStyle: ['line'],
      legend: true,
      x_unit: '',
      y_unit: '%',
      aspect_ratio: null
    }
  },
  mounted() {
    this.getData('daily', new Date());
  },
  methods: {
    onChange(resol, date) {
      this.getData(resol, date);
    },
    getData(resol, date) {
      this.loading = true;
      let query_string = '';
      const params = {
        resol: resol,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate()
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      const url = `/api/devicelog/${this.device_info.device_id}/usage/cpu/${query_string}`;
      axios.get(url)
        .then(response => {
          this.stats = [response.data.map(el => ({value: el.value, x: el.time}))];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
